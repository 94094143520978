import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { heading, body, lastUpdate } from "../legal/terms.js"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms of Use" />
    {/* HERO */}
    <div className="hero1">
      <div className="content taCenter">
        <h1>Terms of Use</h1>
        <p>Last Updated: {lastUpdate.date}</p>
      </div>
    </div>
    <div className="hero3">
      <div className="content taLeft ">
        <div className="legalContent">
          <p className="taJustify">{heading.content}</p>
          {body.map((item, index1) => {
            return (
              <>
                <h3>
                  {index1 + 1}. {item.title}{" "}
                </h3>
                {item.content &&
                  item.content.map((subItem1, index2) => {
                    return (
                      <div
                        key={"subItem1" + index2}
                        style={{ textAlign: "justify" }}
                      >
                        <p>
                          <b>
                            {index1 + 1}.{index2 + 1}
                          </b>
                          &nbsp; {subItem1.text}
                        </p>
                        {subItem1.content &&
                          subItem1.content.map((subItem2, index3) => {
                            // console.log("content: ", subItem2);

                            return (
                              <p
                                key={"subItem2" + index3}
                                style={{ textAlign: "justify" }}
                              >
                                <b>
                                  {index1 + 1}.{index2 + 1}.{index3 + 1}
                                </b>
                                &nbsp; {subItem2.text}
                                {subItem2.content &&
                                  subItem2.content.map((subItem3, index4) => {
                                    // console.log("content: ", subItem2);

                                    return (
                                      <p
                                        key={"subItem3" + index4}
                                        style={{ textAlign: "justify" }}
                                      >
                                        <b>
                                          {index1 + 1}.{index2 + 1}.{index3 + 1}
                                          .{index4 + 1}
                                        </b>
                                        &nbsp; {subItem3.text}
                                      </p>
                                    )
                                  })}
                              </p>
                            )
                          })}
                      </div>
                    )
                  })}
              </>
            )
          })}
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsPage
